import React, { useContext, useState } from "react";
import ReactDOM from "react-dom/client";
import Skeleton from "react-loading-skeleton";
import { App } from "../../../App";
import { useSegmentations } from "../../../hooks/useSegmentation";
import { SidebarRight } from "../project/SidebarRight";
import { ModalForm } from "./modals/Index";
import { Segmentation } from "./Segmentation";
import { useCheckAttr14, useProject } from "../../../hooks/useProject";
import { Alert, AlertTitle } from "@mui/material";
import { useGetSurvey } from "../../../hooks/useSurvey";
import { AuthUserContext } from "../../../context/AuthUserContext";
//isErrorRedirectResponse y errorRedirectResponse captura un Session::flash generado en el backend y se muestra en este componente si
//el redirect falla
export const SegmentationProject = ({ id,iserror:isErrorRedirectResponse,error:errorRedirectResponse }) => {
    const { authUser } = useContext(AuthUserContext);
    const [selectSegmentation, setSelectSegmentation] = useState([]);
    const {checkAttr14,errors, isLoading:isLoadingCheckAttr14} = useCheckAttr14(id);
    const { survey, isLoading:isLoadingSurvey } = useGetSurvey(id);
    const { project } = useProject(id);
    const [seeModal, setSeeModal] = useState({
        create: false,
        edit: false,
        clone: false,
    });

    const {
        isLoading,
        segmentations,
        refetch: getSegmentationsRefetch,
    } = useSegmentations(id);

    const onSelectSegmentation = (segmentation) => {
        setSelectSegmentation(segmentation);
        setSeeModal({ ...seeModal, edit: true });
    };

    return (
        <>
            <div className="row">
                <div className="col-xl-9 col-lg-9 mt-4">
                    <h4>Proyecto: {project.name}</h4>
                    <div className="card">
                        <div className="card-header">
                            <div className="content-head-card">
                                <label>Lista de segmentos</label>
                                { (errors && errors.status == 'ok') && ['super-admin','Project manager','commercial'].includes(authUser.roles[0].name)
                                && <button
                                        className="btn btn-primary-oo"
                                        onClick={(e) => {
                                            e.preventDefault();
                                            setSeeModal({
                                                edit: false,
                                                create: true,
                                            });
                                        }}
                                    >
                                    Nuevo segmento
                                    </button>
                                }

                            </div>
                        </div>
                        {isLoading || isLoadingCheckAttr14 ? (
                            <Skeleton height={400} />
                        )
                        : errors.status == 'error'
                        ?   (<Alert severity="error" sx={{marginTop: '10px',marginBottom: '10px'}}>
                            {
                                errors['attribute_14']['status'] == 'no_attribute_14' && (
                                    <AlertTitle>Este proyecto no tiene configurado el attribute_14 (segment_status).</AlertTitle>
                                )
                            }
                            {
                                errors['tracker']['status'] == 'no_platform_tracker' && (
                                    <AlertTitle>Este proyecto no tiene configurado la opción tracker en la integración de panel</AlertTitle>
                                )
                            }
                            <hr></hr>Solicitar a TI ajustar la programación de la encuesta.
                        </Alert>)
                        : (
                            <div className="card-body">
                                <ModalForm
                                    projectId={id}
                                    segmentation={selectSegmentation}
                                    seeModal={seeModal}
                                    setSeeModal={setSeeModal}
                                />
                                <div
                                    className="accordion"
                                    id="accordionExample"
                                >
                                    { isErrorRedirectResponse == '1' && <Alert severity="error" ><AlertTitle>{errorRedirectResponse}</AlertTitle></Alert> }
                                    {segmentations.map((segmentation) => (
                                        <Segmentation
                                            key={segmentation.id}
                                            segmentation={segmentation}
                                            onSelectSegmentation={() =>
                                                onSelectSegmentation(
                                                    segmentation
                                                )
                                            }
                                            getSegmentationsRefetch={
                                                getSegmentationsRefetch
                                            }
                                        />
                                    ))}
                                </div>
                            </div>
                        )
                        }
                    </div>
                </div>
                {!isLoadingSurvey && <SidebarRight id={id} survey_status={survey.ls_status}/>}
            </div>
        </>
    );
};

if (document.getElementById("jsx-segmentation-project")) {
    const el = document.getElementById("jsx-segmentation-project");
    const Index = ReactDOM.createRoot(el);
    const props = Object.assign({}, el.dataset);
    Index.render(
        <App>
            <SegmentationProject {...props} />
        </App>
    );
}
